const getClassName = (type: any) => {
  let name;
  switch (type) {
    case "停止":
      name = "gray";
      break;
    case "停机":
      name = "gray";
      break;
    case "手工":
      name = "gray";
      break;
    case "示教中":
      name = "gray";
      break;

    case "闲置":
      name = "yellow";
      break;

    case "异常":
      name = "red";
      break;

    case "急停":
      name = "red";
      break;

    case "关闭":
      name = "red";
      break;

    case "运行中":
      name = "green";
      break;

    case "步进中":
      name = "green";
      break;

    case "静止中":
      name = "green";
      break;

    case "抓件中":
      name = "green";
      break;

    case "淬火中":
      name = "green";
      break;

    case "定位中":
      name = "green";
      break;

    case "冲压中":
      name = "green";
      break;

    case "打开":
      name = "green";
      break;

    default:
      name = "green";
  }
  return name;
};
export default getClassName;
